import { createSlice } from '@reduxjs/toolkit'

export const gemeindenSlice = createSlice({
    name: 'content',
    initialState: {
        gemeindenData: [],
        apiData: null,
        currentItem: null
    },
    reducers: {
        setGemeindenData: (state, action) => {
            state.gemeindenData = action.payload.gemeinden
        },
        setApiData: (state, action) => {
            state.apiData = action.payload.apiData
        },
        setCurrentItem: (state,action) =>{
            state.currentItem = action.payload.currentItem
        },
        unsetCurrentItem: (state,action) =>{
            state.currentItem = null
        },
        updateApiDataStatus: (state, action) => {
            let id = state.apiData.assignedObjects.findIndex((el) => el.id === action.payload.id)
            if(state.apiData.assignedObjects[id]!==undefined) {
                state.apiData.assignedObjects[id].hasData = true
                state.apiData.assignedObjects[id].meta = action.payload.meta
                state.apiData.assignedObjects[id].media = action.payload.media
                state.apiData.assignedObjects[id].assignedObjects = action.payload.assignedObjects
            }
        }
    }
})

export const {setGemeindenData,setApiData, updateApiDataStatus, setCurrentItem,unsetCurrentItem } = gemeindenSlice.actions

export default gemeindenSlice.reducer