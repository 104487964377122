import React from "react";
//import {useTranslation} from "react-i18next";
import Container from '@mui/material/Container';
import CircularProgress from '@mui/joy/CircularProgress';

export const Loader = () => {
    //const {t, i18n} = useTranslation();
    return (
        <Container>
            <CircularProgress />
        </Container>
    );
};
