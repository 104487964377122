import { configureStore } from '@reduxjs/toolkit'
import settingsReducer from './settingsReducer'
import contentReducer from './contentReducer'
import filterReducer from './filterReducer'
import {tourinfraApi} from "./tourinfraApi";
import {weatherApi} from "./weatherApi";
import {webcamsApi} from "./webcamsApi";

export default configureStore({
    reducer: {
        [tourinfraApi.reducerPath]:tourinfraApi.reducer,
        [weatherApi.reducerPath]:weatherApi.reducer,
        [webcamsApi.reducerPath]:webcamsApi.reducer,
        settings: settingsReducer,
        content: contentReducer,
        filter: filterReducer
    },
    // Adding the api middleware enables caching, invalidation, polling,
    // and other useful features of `rtk-query`.
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(tourinfraApi.middleware,weatherApi.middleware,webcamsApi.middleware),
})

