import { createSlice } from '@reduxjs/toolkit'

export const filterSlice = createSlice({
    name: 'filter',
    initialState: {
        loipengebiet:true,
        skigebiet:true,
        outdoor_areal:true,
        only_open:false,
        location:null,
        selectValue: 999999999
    },
    reducers: {
        updateFilter: (state, action) => {
            var obj = state;
            console.log(action)
            for (var prop in action.payload) {
                var val = action.payload[prop];
                //if (typeof val == "object") // this also applies to arrays or null!
                //  update(obj[prop], val);
                //else
                obj[prop] = eval(val);
            }

            state = obj;
        },
        updateFilterValue: (state,action) => {
            state[action.payload.field] = action.payload.value;
        }
    }
})

export const { updateFilter,updateFilterValue } = filterSlice.actions

export default filterSlice.reducer