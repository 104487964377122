import {Suspense} from 'react';
import {useDispatch} from 'react-redux';

import {updateSettings} from "./store/settingsReducer";
import {updateFilter} from "./store/filterReducer";
import {Loader} from "./app/Loader";
import loadable from '@loadable/component';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';


const AppController = loadable(props => import(`./${props.template}/AppController`), {
    cacheKey: props => 'ac_' + props.template,
});


function App({gebiet, template, showTopRow, apikey, hide_gallery, filter_loipengebiet, filter_skigebiet, filter_outdoor_areal, filter_only_open}) {
    const dispatch = useDispatch();
    console.log(gebiet)

    template = 'v2'
    dispatch(updateSettings({
        gebiet: gebiet,
        template: template,
        showTopRow: showTopRow,
        apikey: apikey,
        hide_gallery: hide_gallery,
    }))

    dispatch(updateFilter({
        loipengebiet: filter_loipengebiet,
        skigebiet: filter_skigebiet,
        outdoor_areal: filter_outdoor_areal,
        only_open: filter_only_open
    }))
    return (
        <Suspense fallback={<Loader/>}>

                <AppController template={template}/>

        </Suspense>


    );
}

export default App;
