import { createSlice } from '@reduxjs/toolkit'

export const settingsSlice = createSlice({
    name: 'settings',
    initialState: {
        gebiet:0,
        template: 'v2',
        showTopRow:false,
        key:'',
        skip_summary:false,
        start_map_view: false,
        expanded: false,
        sort:'open_first'
    },
    reducers: {
        updateSettings: (state, action) => {
            var obj = state;

            console.log(action)
            for (var prop in action.payload) {
                var val = action.payload[prop];
                //if (typeof val == "object") // this also applies to arrays or null!
                  //  update(obj[prop], val);
                //else
                    obj[prop] = val;
            }

            state = obj;
        }
    }
})

export const { updateSettings } = settingsSlice.actions

export default settingsSlice.reducer