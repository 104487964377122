import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './i18n';
import { Provider } from 'react-redux';
import store from "./store/store";

const widgetDiv = document.getElementById('gs_wintermanager');

if (widgetDiv != null) {
    const root = ReactDOM.createRoot(widgetDiv);
    let gebiet = '0'
    let template = 'v2'
    let apikey = 'xpn2TiHry715xrqijkP2MAFKX5K4sdMibhOXb4OW'
    let showTopRow = false
    let hide_gallery = false
    let filter_loipengebiet = true
    let filter_skigebiet = true
    let filter_outdoor_areal = true
    let filter_only_open = false
    console.log(widgetDiv.dataset);
    if (widgetDiv.dataset.hasOwnProperty('gebiet')) {
        let w_gebiet = widgetDiv.dataset.gebiet;
        if (w_gebiet === '')
            gebiet = 1
        else
            gebiet=w_gebiet;
        console.log(w_gebiet)
    }
    if (widgetDiv.dataset.hasOwnProperty('template')) {
        let w_template = widgetDiv.dataset.template;
        if (w_template === '')
            template = 'v1'
        else
            template=w_template;
    }
    if (widgetDiv.dataset.hasOwnProperty('showTopRow')) {
        let w_showTopRow = widgetDiv.dataset.showTopRow;
        if (w_showTopRow === '' || w_showTopRow==='false')
            showTopRow = false
        else
            showTopRow=w_showTopRow;
    }
    if (widgetDiv.dataset.hasOwnProperty('hide_gallery')) {
        let w_hide_gallery = widgetDiv.dataset.hide_gallery;
        if (w_hide_gallery === '' || w_hide_gallery==='false')
            hide_gallery = false
        else
            hide_gallery=w_hide_gallery;
    }
    if (widgetDiv.dataset.hasOwnProperty('filter_loipengebiet')) {
        let w_filter_loipengebiet = widgetDiv.dataset.filter_loipengebiet;
        if (w_filter_loipengebiet === '' || w_filter_loipengebiet==='true')
            filter_loipengebiet = true
        else
            filter_loipengebiet = w_filter_loipengebiet;
    }
    if (widgetDiv.dataset.hasOwnProperty('filter_skigebiet')) {
        let w_filter_skigebiet = widgetDiv.dataset.filter_skigebiet;
        if (w_filter_skigebiet === '' || w_filter_skigebiet==='true')
            filter_skigebiet = true
        else
            filter_skigebiet = w_filter_skigebiet;
    }
    if (widgetDiv.dataset.hasOwnProperty('filter_outdoor_areal')) {
        let w_filter_outdoor_areal = widgetDiv.dataset.filter_outdoor_areal;
        if (w_filter_outdoor_areal === '' || w_filter_outdoor_areal === 'true')
            filter_outdoor_areal = true
        else
            filter_outdoor_areal=w_filter_outdoor_areal;
    }
    if (widgetDiv.dataset.hasOwnProperty('filter_only_open')) {
        let w_filter_only_open = widgetDiv.dataset.filter_only_open;
        if (w_filter_only_open === '' || w_filter_only_open === 'false')
            filter_only_open = false
        else
            filter_only_open = w_filter_only_open;
    }
    if (widgetDiv.dataset.hasOwnProperty('apikey')) {
        let w_key = widgetDiv.dataset.apikey;
        if (w_key === '')
            apikey = 'xpn2TiHry715xrqijkP2MAFKX5K4sdMibhOXb4OW'
        else
            apikey=w_key;
    }

    console.log('app init');
    root.render(
        <React.StrictMode>
            <Provider store={store}>
            <App gebiet={gebiet} template={template} showTopRow={showTopRow}
                 apikey={apikey}
                 hide_gallery={hide_gallery}
                 filter_loipengebiet={filter_loipengebiet}
                 filter_skigebiet={filter_skigebiet}
                 filter_outdoor_areal={filter_outdoor_areal}
                 filter_only_open={filter_only_open}
            />
            </Provider>
        </React.StrictMode>
    );
} else {
    const root = ReactDOM.createRoot(document.getElementById('root'));
    console.log('app init');
    root.render(
        <React.StrictMode>
            <Provider store={store}>
                <App gebiet='233'
                     template='v2'
                     apikey='xpn2TiHry715xrqijkP2MAFKX5K4sdMibhOXb4OW'
                     showTopRow='true'
                     hide_gallery='false'
                     filter_loipengebiet='true'
                     filter_skigebiet='true'
                     filter_outdoor_areal='true'
                     filter_only_open='false'/>
            </Provider>
        </React.StrictMode>
    );
}

